import {
  ArrowRightEndOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Logo } from '@/components/Utils/Logo';
import LogoWithText from '@/components/Utils/LogoWithText';
import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import { LogoSize, LogoVariant, logoSizeMap } from '@/types';
import { NavigationItem } from '@/types/navigation';
import { classNames } from '@/utils';
import { useNavigate } from 'react-router-dom';

function LogoWithTextSidebar({ sidebarOpen }: { sidebarOpen: boolean }) {
  return sidebarOpen ? (
    <div className="flex justify-center pt-2">
      <LogoWithText
        variant={LogoVariant.LIGHT}
        href="/"
        size={LogoSize.XSMALL}
      />
    </div>
  ) : (
    <Link to="/" className="cursor-pointer hover:opacity-75">
      <div className="flex justify-center">
        <Logo
          variant={LogoVariant.LIGHT}
          classes={`mt-4 h-auto ${logoSizeMap[LogoSize.XSMALL].logo}`}
        />
      </div>
    </Link>
  );
}

function SidebarItemLink({
  item,
  sidebarOpen,
}: {
  item: NavigationItem;
  sidebarOpen: boolean;
}) {
  return sidebarOpen ? (
    <NavLink
      to={item.href}
      className={classNames(
        item?.current
          ? 'bg-slate-800 text-white'
          : 'hover:bg-slate-700 hover:text-white',
        `text-md group flex w-full items-center gap-x-3 rounded-md py-3 pl-4 pr-6 font-medium leading-6 text-slate-900`,
      )}
    >
      <div className="flex items-center gap-x-6">
        <item.icon
          className={classNames(
            item?.current
              ? 'text-white'
              : 'text-slate-900 group-hover:text-white',
            'h-5 w-5 shrink-0',
          )}
          aria-hidden="true"
        />
        <span>{item.name}</span>
      </div>
    </NavLink>
  ) : (
    <Tooltip.Provider delayDuration={50}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <NavLink
            to={item.href}
            className={classNames(
              item?.current
                ? 'bg-slate-800 text-white'
                : 'text-slate-900 hover:bg-slate-700 hover:text-white',
              'group flex gap-x-3 rounded-md px-2 py-3 text-sm font-medium leading-6',
            )}
          >
            <item.icon
              className={classNames(
                item?.current
                  ? 'text-white'
                  : 'text-slate-900 group-hover:text-white',
                'h-5 w-5 shrink-0',
              )}
              aria-hidden="true"
            />
          </NavLink>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="right"
            sideOffset={5}
            className="z-50 rounded-md bg-gray-700 px-2 py-1 text-xs text-white opacity-90"
          >
            {item.name}
            <Tooltip.Arrow className="fill-gray-700" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function SidebarItemButton({
  item,
  sidebarOpen,
  onClick,
}: {
  item: NavigationItem;
  sidebarOpen: boolean;
  onClick?: () => void;
}) {
  return sidebarOpen ? (
    <button
      type="button"
      className={classNames(
        item?.current
          ? 'bg-slate-800 text-white'
          : 'hover:bg-slate-700 hover:text-white',
        `text-md group flex w-full items-center gap-x-3 rounded-md py-3 pl-4 pr-6 font-medium leading-6 text-slate-900`,
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-6">
        <item.icon
          className={classNames(
            item?.current
              ? 'text-white'
              : 'text-slate-900 group-hover:text-white',
            'h-5 w-5 shrink-0',
          )}
          aria-hidden="true"
        />
        <span>{item.name}</span>
      </div>
    </button>
  ) : (
    <Tooltip.Provider delayDuration={50}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            type="button"
            onClick={onClick}
            className={classNames(
              item?.current
                ? 'bg-slate-800 text-white'
                : 'text-slate-900 hover:bg-slate-700 hover:text-white',
              'group flex gap-x-3 rounded-md px-2 py-3 text-sm font-medium leading-6',
            )}
          >
            <item.icon
              className={classNames(
                item?.current
                  ? 'text-white'
                  : 'text-slate-900 group-hover:text-white',
                'h-5 w-5 shrink-0',
              )}
              aria-hidden="true"
            />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="right"
            sideOffset={5}
            className="z-50 rounded-md bg-gray-700 px-2 py-1 text-xs text-white opacity-90"
          >
            {item.name}
            <Tooltip.Arrow className="fill-gray-700" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function CollapseSidebarButton({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}) {
  return (
    <button
      type="button"
      onClick={() => setSidebarOpen(!sidebarOpen)}
      className="ml-auto rounded-full p-2 text-slate-900 hover:bg-slate-100"
    >
      {sidebarOpen ? (
        <ChevronLeftIcon
          className="h-6 w-6 text-slate-900"
          aria-hidden="true"
        />
      ) : (
        <ChevronRightIcon
          className="h-6 w-6 text-slate-900"
          aria-hidden="true"
        />
      )}
    </button>
  );
}

export default function SideBar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  return (
    <div
      className={`flex h-screen overflow-hidden border-r border-gray-200 bg-white ${sidebarOpen ? 'md:min-w-[240px]' : ''}`}
    >
      <div className="flex grow flex-col gap-y-2 px-3">
        <div className={`${sidebarOpen ? 'mr-5' : ''}`}>
          <LogoWithTextSidebar sidebarOpen={sidebarOpen} />
        </div>
        <nav className={`flex flex-1 flex-col md:pt-3`}>
          <ul className="flex flex-1 flex-col">
            <li>
              <ul className="space-y-3">
                {[
                  navigationMap.radar,
                  navigationMap.search,
                  navigationMap.profile,
                ].map((item) => (
                  <li
                    key={item.name}
                    className={`flex ${sidebarOpen ? 'justify-start' : 'justify-center'}`}
                  >
                    <SidebarItemLink item={item} sidebarOpen={sidebarOpen} />
                  </li>
                ))}
                <li
                  key="sign-out"
                  className={`flex ${sidebarOpen ? 'justify-start' : 'justify-center'}`}
                >
                  <SidebarItemButton
                    item={
                      {
                        key: 'sign-out',
                        name: 'Sign Out',
                        href: '/auth/sign-out',
                        icon: ArrowRightEndOnRectangleIcon,
                      } as NavigationItem
                    }
                    sidebarOpen={sidebarOpen}
                    onClick={signOut}
                  />
                </li>
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <div className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-white">
                <CollapseSidebarButton
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
