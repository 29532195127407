import { getFilterById } from '@/consts/radarFilters';
import { Filter } from '@/types/filter';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import MultiSelectDropdownMenu from '../Menus/MultiSelectDropdownMenu';
import Spinner from '../Utils/Spinner';
import SearchBar from './SearchBar';

const SearchPalette = ({
  onSearch,
  searchFilters,
  setSearchFilters,
  isFetchingFilters,
}: {
  onSearch: (query: string) => void;
  searchFilters: Filter[];
  setSearchFilters: (filters: any[]) => void;
  isFetchingFilters: boolean;
}) => {
  const handleSearch = (query: string) => {
    onSearch(query);
    const clearedFilters = searchFilters.map((filter) => ({
      ...filter,
      options: filter.options.map((opt) => ({
        ...opt,
        checked: false,
      })),
    }));
    setSearchFilters(clearedFilters);
  };

  const numJurisdictionsSelected = getFilterById(
    'jurisdiction',
    searchFilters,
  )?.options?.filter((opt) => opt.checked).length;
  const numDocumentTypesSelected = getFilterById(
    'documentType',
    searchFilters,
  )?.options?.filter((opt) => opt.checked).length;
  const numAuthoritiesSelected = getFilterById(
    'authority',
    searchFilters,
  )?.options?.filter((opt) => opt.checked).length;
  const numTopicsSelected = getFilterById(
    'topic',
    searchFilters,
  )?.options?.filter((opt) => opt.checked).length;

  return (
    <div className="flex min-h-[600px] flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-3xl space-y-8">
        {/* Search section */}
        <div className="space-y-4">
          {/* Search bar */}
          <div className="w-full">
            <SearchBar onSearch={handleSearch} />
          </div>

          {/* Loading spinner */}
          {isFetchingFilters ? (
            <div className="flex justify-center">
              <Spinner size="sm" />
            </div>
          ) : (
            /* Filters */
            <div className="flex flex-wrap items-center justify-center gap-2">
              {/* Jurisdiction filter */}
              {getFilterById('jurisdiction', searchFilters) && (
                <MultiSelectDropdownMenu
                  label="Jurisdiction"
                  buttonContent={() => (
                    <div className="flex items-center gap-1">
                      Jurisdiction
                      <div className="flex items-center">
                        {numJurisdictionsSelected > 0 && (
                          <span className="rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-700">
                            {numJurisdictionsSelected}
                          </span>
                        )}
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                      </div>
                    </div>
                  )}
                  options={
                    getFilterById('jurisdiction', searchFilters)?.options?.map(
                      (opt) => ({
                        id: opt.value,
                        label: opt.label,
                      }),
                    ) || []
                  }
                  selectedItems={
                    getFilterById('jurisdiction', searchFilters)
                      ?.options?.filter((opt) => opt.checked)
                      .map((opt) => opt.value) || []
                  }
                  onSelectionChange={(selected) => {
                    const newFilters = [...searchFilters];
                    const jurisdictionFilter = getFilterById(
                      'jurisdiction',
                      newFilters,
                    );
                    if (jurisdictionFilter) {
                      jurisdictionFilter.options =
                        jurisdictionFilter.options.map((opt) => ({
                          ...opt,
                          checked: selected.includes(opt.value),
                        }));
                      setSearchFilters(newFilters);
                    }
                  }}
                  scrollAreaClassName="h-60"
                  buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors min-w-[150px]"
                  showSearch
                />
              )}

              {/* Document Type filter */}
              {getFilterById('documentType', searchFilters) && (
                <MultiSelectDropdownMenu
                  label="Document Type"
                  buttonContent={() => (
                    <div className="flex items-center gap-2">
                      Document Type
                      <div className="flex items-center">
                        {numDocumentTypesSelected > 0 && (
                          <span className="rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-700">
                            {numDocumentTypesSelected}
                          </span>
                        )}
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                      </div>
                    </div>
                  )}
                  options={
                    getFilterById('documentType', searchFilters)?.options?.map(
                      (opt) => ({
                        id: opt.value,
                        label: opt.label,
                      }),
                    ) || []
                  }
                  selectedItems={
                    getFilterById('documentType', searchFilters)
                      ?.options?.filter((opt) => opt.checked)
                      .map((opt) => opt.value) || []
                  }
                  onSelectionChange={(selected) => {
                    const newFilters = [...searchFilters];
                    const typeFilter = getFilterById(
                      'documentType',
                      newFilters,
                    );
                    if (typeFilter) {
                      typeFilter.options = typeFilter.options.map((opt) => ({
                        ...opt,
                        checked: selected.includes(opt.value),
                      }));
                      setSearchFilters(newFilters);
                    }
                  }}
                  scrollAreaClassName="h-60"
                  buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors min-w-[150px]"
                  showSearch
                />
              )}

              {/* Authority filter */}
              {getFilterById('authority', searchFilters) && (
                <MultiSelectDropdownMenu
                  label="Authority"
                  buttonContent={() => (
                    <div className="flex items-center gap-2">
                      Authority
                      <div className="flex items-center">
                        {numAuthoritiesSelected > 0 && (
                          <span className="rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-700">
                            {numAuthoritiesSelected}
                          </span>
                        )}
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                      </div>
                    </div>
                  )}
                  options={
                    getFilterById('authority', searchFilters)?.options?.map(
                      (opt) => ({
                        id: opt.value,
                        label: opt.label,
                      }),
                    ) || []
                  }
                  selectedItems={
                    getFilterById('authority', searchFilters)
                      ?.options?.filter((opt) => opt.checked)
                      .map((opt) => opt.value) || []
                  }
                  onSelectionChange={(selected) => {
                    const newFilters = [...searchFilters];
                    const authorityFilter = getFilterById(
                      'authority',
                      newFilters,
                    );
                    if (authorityFilter) {
                      authorityFilter.options = authorityFilter.options.map(
                        (opt) => ({
                          ...opt,
                          checked: selected.includes(opt.value),
                        }),
                      );
                      setSearchFilters(newFilters);
                    }
                  }}
                  scrollAreaClassName="h-60"
                  buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors min-w-[150px]"
                  showSearch
                />
              )}

              {/* Topic filter */}
              {getFilterById('topic', searchFilters) && (
                <MultiSelectDropdownMenu
                  label="Topic"
                  buttonContent={() => (
                    <div className="flex items-center gap-2">
                      Topic
                      <div className="flex items-center">
                        {numTopicsSelected > 0 && (
                          <span className="rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-700">
                            {numTopicsSelected}
                          </span>
                        )}
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                      </div>
                    </div>
                  )}
                  options={
                    getFilterById('topic', searchFilters)?.options?.map(
                      (opt) => ({
                        id: opt.value,
                        label: opt.label,
                      }),
                    ) || []
                  }
                  selectedItems={
                    getFilterById('topic', searchFilters)
                      ?.options?.filter((opt) => opt.checked)
                      .map((opt) => opt.value) || []
                  }
                  onSelectionChange={(selected) => {
                    const newFilters = [...searchFilters];
                    const topicFilter = getFilterById('topic', newFilters);
                    if (topicFilter) {
                      topicFilter.options = topicFilter.options.map((opt) => ({
                        ...opt,
                        checked: selected.includes(opt.value),
                      }));
                      setSearchFilters(newFilters);
                    }
                  }}
                  scrollAreaClassName="h-60"
                  buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors min-w-[150px]"
                  showSearch
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPalette;
