import {
  QueryParams,
  queryRagStream,
  SummarizeParams,
  summarizeRag,
  getRagContent,
} from '@/api/rag';
import { RagArticleResult, RagResult } from '@/types/rag';
import { Summary, DocumentSummary } from '@/types/notification';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';

export const useRag = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [results, setResults] = useState<string | null>(null);
  const [skeleton, setSkeleton] = useState<RagResult | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [summaryCitations, setSummaryCitations] = useState<Summary | null>(
    null,
  );

  const { mutateAsync: performQueryInternal, isPending: isLoading } =
    useMutation<AsyncGenerator<string, void, unknown>, Error, QueryParams>({
      mutationFn: (params: QueryParams) => {
        const signal = abortControllerRef.current?.signal;
        return queryRagStream(params, signal);
      },
    });

  const performQuery = useCallback(
    async (params: Omit<QueryParams, 'abortSignal'>) => {
      // Cancel any ongoing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      setError(null);
      // Create a new AbortController for this request
      abortControllerRef.current = new AbortController();

      try {
        let hasSkeleton = false;
        let hasSummaryCitations = false;
        setResults(null);
        setSkeleton(null);
        setSummaryCitations(null);
        const result = await performQueryInternal({
          ...params,
          abortSignal: abortControllerRef.current.signal,
        });

        // parse and set results from the stream
        for await (const chunk of result) {
          // handle aborts
          if (abortControllerRef.current?.signal.aborted) break;
          if (!chunk) {
            continue;
          }
          // handle strings -> this is automatically assumed to be part of the main summary
          if (typeof chunk === 'string') {
            setResults((prevResults) => (prevResults ?? '') + chunk);
          } // check if the skeleton has been set - there should only be one
          else if (!hasSkeleton) {
            hasSkeleton = true;
            // cast to RagResult
            const ragResult = chunk as RagResult;
            ragResult.articles = ragResult.articles.map(
              (article: RagArticleResult) => ({
                ...article,
              }),
            );
            setSkeleton(ragResult);
          }
          // check if the summary citations have been set - there should only be one
          else if (!hasSummaryCitations) {
            hasSummaryCitations = true;
            setSummaryCitations(chunk);
          }
        }

        if (!hasSkeleton || !hasSummaryCitations) {
          throw new Error('Query failed, please try again');
        }

        return result;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log('Query aborted');
        } else {
          console.log('Query failed', error);
          setError(error as Error);
        }
      }
    },
    [performQueryInternal],
  );

  return {
    performQuery,
    results: skeleton ?? {
      articles: [],
      summary: { summary: '', detailedSummaries: [] },
      filters: { query: '', filters: {} },
      stage: '',
    },
    summary: {
      summary: results ?? '',
      detailedSummaries: summaryCitations ? [summaryCitations] : [],
    } as DocumentSummary,
    error,
    isLoading,
    emptyState: skeleton?.articles?.length === 0,
  };
};

export const useRagSummarize = () => {
  const {
    mutateAsync: performQueryInternal,
    data: results,
    error,
    isPending: isLoading,
  } = useMutation<Summary, Error, SummarizeParams>({
    mutationFn: summarizeRag,
  });

  const performQuery = useCallback(
    // obtain the Summary for a given article and chunks
    async (article: RagArticleResult, query: string) => {
      try {
        const result = await performQueryInternal({
          query: query,
          content_id: article.contentId,
          chunk_ids: article.chunkIds,
        });
        return result;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log('Query aborted');
        } else {
          console.log('Query failed', error);
          throw error;
        }
      }
    },
    [performQueryInternal],
  );

  const {
    mutateAsync: getRagContentInternal,
    data: content,
    error: contentError,
    isPending: isLoadingContent,
  } = useMutation<string[], Error, RagArticleResult>({
    mutationFn: getRagContent,
  });

  const getRagContentInner = useCallback(
    // obtain the Summary for a given article and chunks
    async (article: RagArticleResult) => {
      try {
        const result = await getRagContentInternal(article);
        return result;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log('Query aborted');
        } else {
          console.log('Query failed', error);
          throw error;
        }
      }
    },
    [getRagContentInternal],
  );

  return {
    performQuery,
    getRagContent: getRagContentInner,
    results: results ?? { bullets: [] },
    error,
    isLoading,
    contentError,
    isLoadingContent,
  };
};
