import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import { classNames } from '@/utils';

export default function NavBar() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const sections = [
    navigationMap.radar,
    navigationMap.search,
    navigationMap.profile,
  ];

  const mobileMenu = (
    <div className="px-8 pb-3 pt-1 sm:px-6">
      <div className="mt-3 space-y-1">
        {sections.map((item) => (
          <Disclosure.Button
            key={item.name}
            type="button"
            onClick={() => {
              navigate(item.href);
            }}
            className="block w-full py-2 text-left text-slate-900"
          >
            {item.name}
          </Disclosure.Button>
        ))}
        {
          <Disclosure.Button
            key="logout"
            type="button"
            onClick={signOut}
            className="block w-full py-2 text-left text-slate-900"
          >
            Sign Out
          </Disclosure.Button>
        }
      </div>
    </div>
  );

  const profileDropDownMenuDesktop = (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {sections.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <button
                type="button"
                onClick={() => {
                  navigate(item.href);
                }}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block w-full px-4 py-2 text-left text-sm text-gray-700',
                )}
              >
                {item.name}
              </button>
            )}
          </Menu.Item>
        ))}
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={signOut}
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block w-full px-4 py-2 text-left text-sm text-gray-700',
              )}
            >
              Sign Out
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  );

  function mobileMenuButton(open: boolean) {
    return (
      <div className="flex">
        <div className="flex items-center md:hidden">
          {/* Mobile menu button */}
          <Disclosure.Button
            className={`inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500`}
          >
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            )}
          </Disclosure.Button>
        </div>
      </div>
    );
  }

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className={`mx-auto max-w-full px-4 sm:px-6 lg:px-12`}>
            <div className="flex h-12 justify-end pl-2 md:pl-0">
              {/* <SearchBar /> */}
              <div className="flex items-center">
                {mobileMenuButton(open)}
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <Menu as="div" className="relative ml-3">
                    <Menu.Button className="flex items-center rounded-full p-1.5">
                      {/* Profile dropdown menu desktop */}
                      {/* <ProfileAvatar
                        email={"morgan.alvarex@gmail.com"}
                        variant={ProfileVariant.DARK}
                      /> */}
                      <span className="hidden lg:flex lg:items-center">
                        {/* <span
                          className="ml-4 text-sm font-medium leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          Morgan Alvarez
                        </span> */}
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    {profileDropDownMenuDesktop}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            {mobileMenu}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
