import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import React from 'react';

interface Props {
  onSearch: (query: string) => void;
}

// this should be inside the function but not working because of re-rendering atm...
let timeoutId: any | null = null;
function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      // Wrap the function call in a Promise to make it asynchronous
      Promise.resolve().then(() => func(...args));
    }, delay);
  };
}

const SearchBar: React.FC<Props> = ({ onSearch }) => {
  const debouncedOnSearch = debounce(onSearch, 500);

  const [query, setQuery] = React.useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    debouncedOnSearch(query);
  };

  // TODO: disabled for now as we're collapsing the search bar
  // React.useEffect(() => {
  //   debouncedOnSearch(query);
  // }, [query]);

  return (
    <form className="relative w-full" onSubmit={handleSubmit}>
      <div className="relative">
        <MagnifyingGlassIcon
          className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        <input
          type="text"
          className="sm:text-md block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-4 pl-10 pr-10 text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
          placeholder="What can I help you with?"
          style={{
            outline: 'none',
            borderColor: 'transparent !important',
          }}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        {query && query.trim() !== '' && (
          <button
            type="submit"
            className="group absolute right-2 top-2 rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
          >
            <ArrowRightIcon className="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:translate-x-0.5 group-hover:text-gray-600" />
          </button>
        )}
      </div>
    </form>
  );
};

export default SearchBar;
