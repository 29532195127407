import {
  getNotifications,
  getNotificationById,
  GetNotificationsResponse,
} from '@/api/notifications';
import { Filter } from '@/types/filter';
import { NotificationResult } from '@/types/notification';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

const NOTIFICATION_QUERY_KEY = 'NOTIFICATIONS';

export function useNotifications({
  page,
  pageSize,
  profileIds,
  countryFilter,
  documentTypeFilter,
  authorityFilter,
  topicFilter,
  startDate,
  endDate,
  sortField = 'date',
  sortDirection = 'desc',
  searchFilter,
  notificationId,
}: {
  page: number;
  pageSize: number;
  profileIds: string[];
  countryFilter: Filter;
  documentTypeFilter: Filter;
  authorityFilter: Filter;
  topicFilter: Filter;
  startDate: Date;
  endDate: Date;
  sortField?: 'date' | 'risk';
  sortDirection?: 'asc' | 'desc';
  searchFilter?: string;
  notificationId?: string;
}) {
  const {
    data: notificationsData,
    error: notificationsError,
    isFetching: isFetchingNotifications,
  } = useQuery({
    queryKey: [
      NOTIFICATION_QUERY_KEY,
      profileIds,
      page,
      pageSize,
      countryFilter,
      documentTypeFilter,
      authorityFilter,
      topicFilter,
      startDate,
      endDate,
      sortField,
      sortDirection,
      searchFilter,
    ],
    queryFn: async (): Promise<GetNotificationsResponse> => {
      if (!profileIds.length) {
        return { notifications: [], total: 0 };
      }
      const res = await getNotifications(
        profileIds,
        page,
        pageSize,
        [startDate.toISOString(), endDate.toISOString()],
        {
          field: sortField,
          direction: sortDirection,
        },
        searchFilter
          ? searchFilter.split(' ').filter((word) => word.length > 0)
          : [],
        documentTypeFilter.options
          .filter((option) => option.checked)
          .map((option) => option.value),
        authorityFilter.options
          .filter((option) => option.checked)
          .map((option) => option.value),
        countryFilter.options
          .filter((option) => option.checked)
          .map((option) => ({
            country: option.value,
          })),
        topicFilter.options
          .filter((option) => option.checked)
          .map((option) => option.value),
      );
      return res;
    },
  });

  const notifications = useMemo(
    () => notificationsData?.notifications,
    [notificationsData],
  );

  const {
    data: notificationByIdData,
    error: notificationByIdError,
    isLoading: isFetchingNotificationById,
  } = useQuery<NotificationResult, Error>({
    queryKey: ['notification', notificationId],
    queryFn: () => getNotificationById(notificationId || ''),
    enabled: !!notificationId,
  });

  return {
    isFetchingNotifications,
    notificationsError,
    notifications,
    totalCount: notificationsData?.total,
    totalPages: notificationsData?.total
      ? Math.ceil(notificationsData.total / pageSize)
      : 0,
    notificationByIdData,
    notificationByIdError,
    isFetchingNotificationById,
  };
}
