import moment from 'moment';

const getMoment = (dateTime: string) => {
  return moment(dateTime).format('MMM D, h:mmA');
};

const formattedDate = (dateTime: string) => {
  return getMoment(dateTime);
};

export default function ShortDateTime({ dateTime }: { dateTime: string }) {
  return <span>{formattedDate(dateTime)}</span>;
}
