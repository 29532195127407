import { NavigationItem } from '@/types/navigation';
import { Aperture, RssSimple, SlidersHorizontal } from '@phosphor-icons/react';

export enum NavigationKey {
  Search = 'search',
  Radar = 'radar',
  Profile = 'profile',
  Register = 'register',
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
}

export const navigationMap: Record<NavigationKey, NavigationItem> = {
  [NavigationKey.Search]: {
    key: NavigationKey.Search,
    name: 'AI Search',
    href: '/search',
    icon: Aperture as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
  [NavigationKey.Radar]: {
    key: NavigationKey.Radar,
    name: 'Radar',
    href: '/',
    icon: RssSimple as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
  [NavigationKey.Profile]: {
    key: NavigationKey.Profile,
    name: 'Trackers',
    href: '/trackers',
    icon: SlidersHorizontal as (
      props: React.ComponentProps<'svg'>,
    ) => JSX.Element,
  },
  [NavigationKey.Register]: {
    key: NavigationKey.Register,
    name: 'Register',
    href: '/register',
    icon: Aperture as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
  [NavigationKey.Login]: {
    key: NavigationKey.Login,
    name: 'Login',
    href: '/login',
    icon: Aperture as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
  [NavigationKey.ForgotPassword]: {
    key: NavigationKey.ForgotPassword,
    name: 'Forgot Password',
    href: '/forgot-password',
    icon: Aperture as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
  [NavigationKey.ResetPassword]: {
    key: NavigationKey.ResetPassword,
    name: 'Reset Password',
    href: '/forgot-password/reset',
    icon: Aperture as (props: React.ComponentProps<'svg'>) => JSX.Element,
  },
};
