import { Article } from '@/types/notification';
import { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';

const DocumentAsMarkdown = ({
  doc,
  highlightIndex,
}: {
  doc: Article;
  highlightIndex: number | null;
}) => {
  const refs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    if (highlightIndex !== null && refs.current[highlightIndex]) {
      refs.current[highlightIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [highlightIndex]);

  const formattedChunks = (content: string | string[]) => {
    const chunks = Array.isArray(content) ? content : [content];
    const result: string[] = [];
    let carryOver = '';

    for (let i = 0; i < chunks.length; i++) {
      let currentString = carryOver + chunks[i];
      carryOver = '';

      if (!currentString.endsWith('.')) {
        const lastPeriodIndex = currentString.lastIndexOf('.');
        if (lastPeriodIndex !== -1) {
          carryOver = currentString.slice(lastPeriodIndex + 1);
          currentString = currentString.slice(0, lastPeriodIndex + 1);
        }
      }

      result.push(currentString);
    }

    if (carryOver) {
      result[result.length - 1] += carryOver;
    }

    return result;
  };

  const proseTextStyle = (highlight: boolean) =>
    `prose-li:marker:text-gray-500 ${highlight ? 'bg-yellow-100' : ''}`;

  return (
    <div className="flex-col px-4 py-4">
      {formattedChunks(doc.chunks).map((chunk: string, index: number) => {
        return (
          <div key={index} ref={(el) => (refs.current[index] = el)}>
            <Markdown
              className={`prose prose-sm w-full min-w-full max-w-full flex-grow flex-col ${proseTextStyle(index === highlightIndex)}`}
              components={{
                /* eslint-disable */
                p: ({ node, ...props }) => (
                  <p {...props} className="w-full py-2 text-left" />
                ),
                li: ({ node, ...props }) => (
                  <li {...props} className="-my-1 w-full text-left" />
                ),
                ul: ({ node, ...props }) => (
                  <ul {...props} className="w-full text-left" />
                ),
                // table: ({ node, ...props }) => (
                table: ({ node, ...props }) => (
                  <table {...props} className="w-full text-left" />
                ),
                /* eslint-enable */
              }}
              disallowedElements={['pre', 'code']}
            >
              {chunk}
            </Markdown>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentAsMarkdown;
