import ScrollContainer from '@/components/List/ScrollContainer';
import { useProfiles } from '@/hooks/useProfiles';
import { useProfilesContext } from '@/providers/profilesProvider';
import { useUserContext } from '@/providers/userProvider';
import { Profile } from '@/types/profiles';
import {
  BookOpenIcon,
  BriefcaseIcon,
  GlobeAltIcon,
  ScaleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import Modal from '../Utils/Modal';
import ProfileAvatar from './ProfileAvatar';

interface ProfileViewProps {
  profile: Profile;
  onClose: () => void;
}

const ProfileView: React.FC<ProfileViewProps> = ({ profile, onClose }) => {
  const { user } = useUserContext();
  const { deleteProfile } = useProfiles(user?.userId);
  const { refetchProfiles } = useProfilesContext();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteProfileInner = useCallback(async () => {
    await deleteProfile({ profileId: profile.profileId });
    await refetchProfiles();
    onClose();
  }, [deleteProfile, refetchProfiles, onClose, profile]);

  return (
    <ScrollContainer>
      <div className="p-6">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <ProfileAvatar name={profile.name} className="mr-4" size="base" />
            <h2 className="text-xl font-semibold text-gray-900">
              {profile.name}
            </h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <ProfileSection title="Regulations" items={profile.regulations} />
        <ProfileSection title="Services" items={profile.services} />
        <ProfileSection
          title="Compliance Topics"
          items={profile.complianceTopics}
        />
        {/* <ProfileSection title="Jurisdictions" items={profile.jurisdictions} /> */}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => {
            setDeleteModalOpen(true);
          }}
          className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Delete Profile
        </button>
      </div>
      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-semibold">Delete Profile</h2>
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this profile?
          </p>
          <div className="flex justify-end gap-2">
            <button
              className="inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={deleteProfileInner}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </ScrollContainer>
  );
};

const ProfileSection: React.FC<{ title: string; items: string[] }> = ({
  title,
  items,
}) => {
  if (items.length === 0) return null;
  const Icon = sectionIcons[title as keyof typeof sectionIcons];
  return (
    <div className="mt-8">
      <div className="mb-2 flex items-center">
        <span className="mr-3 inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
          <Icon className="h-5 w-5" aria-hidden="true" />
        </span>
        <h3 className="text-base font-semibold text-gray-700">{title}</h3>
      </div>
      <hr className="mb-4 border-t border-gray-200" />
      <ul className="space-y-2">
        {items.map((item, idx) => (
          <li key={idx} className="text-sm text-gray-600">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const sectionIcons = {
  Regulations: ScaleIcon,
  Services: BriefcaseIcon,
  'Compliance Topics': BookOpenIcon,
  Jurisdictions: GlobeAltIcon,
};

export default ProfileView;
