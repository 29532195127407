import SearchPalette from '@/components/Search/SearchPalette';
import SearchResults from '@/components/Search/SearchResults';
import { getFilterById } from '@/consts/radarFilters';
import { useRag } from '@/hooks/useRag';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { useRagFilters } from '@/providers/ragFiltersProvider';
import { Filter } from '@/types/filter';
import { RagArticleResult } from '@/types/rag';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

export default function SearchPage() {
  const [selectedResult, setSelectedResult] =
    useState<RagArticleResult | null>(null);
  const [searchFilters, setSearchFilters] = useState<Filter[]>([]);
  const [query, setQuery] = useState<string>('');
  const [hasQuery, setHasQuery] = useState<boolean>(false);
  const { performQuery, results, summary, isLoading, emptyState, error } =
    useRag();
  const { selectFilterOptions, isFetchingFilters } = useRagFilters();

  useEffect(() => {
    setSearchFilters(selectFilterOptions);
  }, [selectFilterOptions]);

  const onSearch = async (query: string) => {
    if (!query || query === '') {
      setHasQuery(false);
      return;
    }

    setHasQuery(true);
    setQuery(query);

    // Get active filters
    const countryFilter = getFilterById('jurisdiction', searchFilters);
    const documentTypeFilter = getFilterById('documentType', searchFilters);
    const authorityFilter = getFilterById('authority', searchFilters);
    const topicsFilter = getFilterById('topic', searchFilters);

    const activeFilters = {
      jurisdiction: countryFilter.options
        .filter((option) => option.checked)
        .map((option) => ({
          country: option.value,
        })),
      documentType: documentTypeFilter.options
        .filter((option) => option.checked)
        .map((option) => option.value),
      authority: authorityFilter.options
        .filter((option) => option.checked)
        .map((option) => option.value),
      topic: topicsFilter.options
        .filter((option) => option.checked)
        .map((option) => option.value),
    };

    await performQuery({
      query,
      limit: 10,
      offset: 0,
      filters: {
        documentType: activeFilters.documentType,
        jurisdiction: activeFilters.jurisdiction,
        source: activeFilters.authority,
        topic: activeFilters.topic,
        dateAfter: null,
        dateBefore: null,
      },
    });

    setSelectedResult(null);
  };

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        {/* Main content area with transitions */}
        <div className="h-full flex-1 overflow-hidden">
          <div className="relative h-full">
            <div className="h-full flex-1 overflow-hidden">
              <Transition
                show={!hasQuery}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <SearchPalette
                  onSearch={onSearch}
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                  isFetchingFilters={isFetchingFilters}
                />
              </Transition>

              <Transition
                show={hasQuery}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="h-full"
              >
                <div className="relative h-full">
                  <button
                    className="absolute top-1 z-50 cursor-pointer rounded-md border-0 bg-gray-100 p-2 transition-colors hover:bg-gray-200 focus:ring-0"
                    onClick={() => {
                      setHasQuery(false);
                      setSelectedResult(null);
                    }}
                  >
                    <ArrowLeftIcon className="h-6 w-6" />
                  </button>
                  <SearchResults
                    results={results}
                    summary={summary}
                    selectedResult={selectedResult}
                    setSelectedResult={setSelectedResult}
                    isLoading={isLoading}
                    emptyState={emptyState}
                    error={error}
                    query={query}
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
