import ScrollArea from '@/layouts/ScrollArea';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, ReactNode, useState } from 'react';
import SearchFilter from '../Filters/SearchFilter';

interface MultiSelectDropdownMenuProps {
  label: string;
  options: { id: string; label: React.ReactNode }[];
  selectedItems: string[];
  onSelectionChange: (selectedIds: string[]) => void;
  buttonClassName?: string;
  itemsClassName?: string;
  scrollAreaClassName?: string;
  buttonContent?: (selectedItems: string[]) => ReactNode;
  showSearch?: boolean;
}

export default function MultiSelectDropdownMenu({
  label,
  options,
  selectedItems,
  onSelectionChange,
  buttonClassName = '',
  itemsClassName = '',
  scrollAreaClassName,
  buttonContent,
  showSearch = false,
}: MultiSelectDropdownMenuProps) {
  const [searchQuery, setSearchQuery] = useState(showSearch ? '' : undefined);

  const filteredOptions = searchQuery
    ? options.filter((option) =>
        String(option.label).toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : options;

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      onSelectionChange(selectedItems.filter((item) => item !== id));
    } else {
      onSelectionChange([...selectedItems, id]);
    }
  };

  const optionsList = (
    <div className="py-1">
      {filteredOptions.map((option) => (
        <Menu.Item key={option.id}>
          {({ active }) => (
            <button
              onClick={(event) => {
                event.preventDefault();
                toggleSelection(option.id);
              }}
              className={`flex w-full items-center px-4 py-2 text-sm ${
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
              }`}
            >
              <input
                type="checkbox"
                checked={selectedItems.includes(option.id)}
                readOnly
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600"
              />
              <span className="flex-1 text-left">{option.label}</span>
            </button>
          )}
        </Menu.Item>
      ))}
    </div>
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={`group inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 ${buttonClassName}`}
      >
        {buttonContent ? (
          buttonContent(selectedItems)
        ) : (
          <>
            {label}
            <ChevronDownIcon
              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </>
        )}
      </Menu.Button>

      {options.length > 0 && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${itemsClassName}`}
          >
            {showSearch && (
              <div className="p-2">
                <SearchFilter
                  query={searchQuery ?? ''}
                  setQuery={setSearchQuery}
                />
              </div>
            )}

            {scrollAreaClassName ? (
              <div className={scrollAreaClassName}>
                <ScrollArea>{optionsList}</ScrollArea>
              </div>
            ) : (
              optionsList
            )}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}
