import CreateProfileView from '@/components/Profiles/CreateProfileView';
import ProfileListItem from '@/components/Profiles/ProfileListItem';
import ProfileView from '@/components/Profiles/ProfileView';
import { Profile } from '@/types/profiles';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

interface ProfileListProps {
  profiles: Profile[];
}

const ProfileList: React.FC<ProfileListProps> = ({ profiles }) => {
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingInner, setIsCreatingInner] = useState(false);
  const [selectedProfileInner, setSelectedProfileInner] =
    useState<Profile | null>(null);
  const [showDetailedView, setShowDetailedView] = useState(false);

  const showDetailedViewInner = !!selectedProfileInner || isCreatingInner;

  useEffect(() => {
    setTimeout(() => {
      setShowDetailedView(showDetailedViewInner);
    }, 100);
  }, [showDetailedViewInner]);

  useEffect(() => {
    setTimeout(() => {
      setSelectedProfile(selectedProfileInner);
    }, 100);
  }, [selectedProfileInner]);

  useEffect(() => {
    setTimeout(() => {
      setIsCreating(isCreatingInner);
    }, 100);
  }, [isCreatingInner]);

  return (
    <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl py-6">
        <div className="space-y-6">
          {!showDetailedView && (
            <div
              className="transition-opacity duration-200 ease-in-out"
              style={{ opacity: showDetailedViewInner ? 0 : 1 }}
            >
              <div className="mb-6 flex items-end justify-between">
                <h2 className="text-xl font-semibold text-gray-900">
                  Trackers
                </h2>
                <button
                  onClick={() => {
                    setSelectedProfileInner(null);
                    setIsCreatingInner(true);
                  }}
                  className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  <PlusIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Create
                </button>
              </div>
              <hr className="mb-6 border-t border-gray-200" />

              {profiles.length === 0 ? (
                <NoProfiles onCreateProfile={() => setIsCreatingInner(true)} />
              ) : (
                <div className="space-y-4">
                  {profiles.map((profile, idx) => (
                    <React.Fragment key={profile.profileId}>
                      {idx > 0 && (
                        <hr className="my-4 border-t border-gray-200" />
                      )}
                      <div className="py-2">
                        <ProfileListItem
                          profile={profile}
                          onEdit={() => {
                            setSelectedProfileInner(profile);
                            setIsCreatingInner(true);
                          }}
                          onClick={() => setSelectedProfileInner(profile)}
                          isSelected={selectedProfileInner === profile}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          )}

          {showDetailedView && (
            <div
              className="relative transition-opacity duration-200 ease-in-out"
              style={{ opacity: showDetailedViewInner ? 1 : 0 }}
            >
              {isCreating ? (
                <CreateProfileView
                  onClose={() => {
                    setIsCreatingInner(false);
                    setSelectedProfileInner(null);
                  }}
                  profile={selectedProfile ?? undefined}
                />
              ) : (
                selectedProfile && (
                  <ProfileView
                    profile={selectedProfile}
                    onClose={() => setSelectedProfileInner(null)}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoProfiles = ({ onCreateProfile }: { onCreateProfile: () => void }) => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <UserIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">
      {"We couldn't find any trackers for you"}
    </p>
    <p className="text-md mt-2">
      <button
        onClick={onCreateProfile}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onCreateProfile();
          }
        }}
        className="cursor-pointer text-blue-500 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Create a new tracker
      </button>{' '}
      to get started
    </p>
  </div>
);

export default ProfileList;
