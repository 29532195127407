import { Jurisdiction } from '@/types';
import { NotificationResult } from '@/types/notification';
import api from './index';

export interface GetNotificationsResponse {
  notifications: NotificationResult[];
  total: number;
}

export interface GetFilterOptionsResponse {
  documentTypes: string[];
  sources: string[];
  countries: string[];
  states: string[];
  regions: string[];
  topics: string[];
}

export type SortField = 'date' | 'risk';
export type SortDirection = 'asc' | 'desc';

export interface SortOrder {
  field: SortField;
  direction: SortDirection;
}

export interface NotificationsRequest {
  profile_ids: string[];
  date_range?: [string, string];
  sort?: SortOrder;
  keywords?: string[];
  document_type?: string[];
  source?: string[];
  jurisdiction?: Jurisdiction[];
  topic?: string[];
}

export const getNotifications = async (
  profileIds: string[],
  page: number,
  pageSize: number,
  dateRange?: [string, string],
  sort?: { field: SortField; direction: SortDirection },
  keywords?: string[],
  documentType?: string[],
  source?: string[],
  jurisdiction?: Jurisdiction[],
  topic?: string[],
): Promise<GetNotificationsResponse> => {
  const request: NotificationsRequest = {
    profile_ids: profileIds,
    date_range: dateRange,
    sort: sort
      ? {
          field: sort.field,
          direction: sort.direction,
        }
      : undefined,
    keywords: keywords,
    document_type: documentType,
    source: source,
    jurisdiction: jurisdiction,
    topic: topic,
  };

  const response = await api.post(
    `notifications?page=${page}&page_size=${pageSize}`,
    request,
  );
  return response.data;
};

export const getNotificationById = async (
  id: string,
): Promise<NotificationResult> => {
  const response = await api.get(`notifications/${id}`);
  return response.data;
};

export async function getFilterOptions(
  profileIds: string[],
): Promise<GetFilterOptionsResponse> {
  const response = await api.post('notifications/filters', {
    profile_ids: profileIds,
  });
  return response.data;
}
