import ScrollContainer from '@/components/List/ScrollContainer';
import { useProfiles } from '@/hooks/useProfiles';
import { useProfilesContext } from '@/providers/profilesProvider';
import { Profile } from '@/types/profiles';
import {
  BookOpenIcon,
  BriefcaseIcon,
  GlobeAltIcon,
  ScaleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';

interface Props {
  onClose: () => void;
  profile?: Profile;
}

const CreateProfileView: React.FC<Props> = ({ onClose, profile }) => {
  const isEditing = !!profile;
  const [profileName, setProfileName] = useState(profile?.name || '');
  const [regulations, setRegulations] = useState(profile?.regulations || ['']);
  const [services, setServices] = useState(profile?.services || ['']);
  const [complianceTopics, setComplianceTopics] = useState(
    profile?.complianceTopics || [''],
  );
  const [jurisdictions, setJurisdictions] = useState(
    profile?.jurisdictions || [''],
  );

  const { createProfile, updateProfile, isLoading } = useProfiles(undefined);
  const { refetchProfiles } = useProfilesContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const profileData = {
      name: profileName,
      regulations: regulations.filter(Boolean),
      services: services.filter(Boolean),
      complianceTopics: complianceTopics.filter(Boolean),
      jurisdictions: jurisdictions.filter(Boolean),
      data: {},
      profileId: profile?.profileId,
    };
    if (isEditing) {
      await updateProfile({ profileData });
    } else {
      await createProfile({ profileData });
    }
    await refetchProfiles();
    onClose();
  };

  const isFormValid =
    profileName.trim() !== '' &&
    (regulations.some((item) => item.trim() !== '') ||
      services.some((item) => item.trim() !== '') ||
      complianceTopics.some((item) => item.trim() !== '') ||
      jurisdictions.some((item) => item.trim() !== ''));

  return (
    <ScrollContainer>
      <div className="px-6">
        <div className="mb-6 flex items-end justify-between pt-2">
          <h2 className="text-xl font-semibold text-gray-900">
            {isEditing ? 'Edit Tracker' : 'Create Tracker'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <hr className="mb-6 border-t border-gray-200" />
        <form onSubmit={handleSubmit} className="p-2">
          <div className="mb-6">
            <label
              htmlFor="profileName"
              className="block text-sm font-medium text-gray-700"
            >
              Profile Name
            </label>
            <input
              type="text"
              id="profileName"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              className="mt-1 block w-full max-w-xl rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              required
            />
          </div>

          <ProfileSection
            title="Regulations"
            items={regulations}
            onChange={(newItems) => setRegulations(newItems)}
          />
          <ProfileSection
            title="Services"
            items={services}
            onChange={(newItems) => setServices(newItems)}
          />
          <ProfileSection
            title="Compliance Topics"
            items={complianceTopics}
            onChange={(newItems) => setComplianceTopics(newItems)}
          />
          {/* <ProfileSection
            title="Jurisdictions"
            items={jurisdictions}
            onChange={(newItems) => setJurisdictions(newItems)}
          /> */}

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              disabled={isLoading || !isFormValid}
              className={`inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${
                isLoading || !isFormValid
                  ? 'cursor-not-allowed bg-gray-300'
                  : 'bg-blue-600 hover:bg-blue-500'
              }`}
            >
              {isLoading ? 'Saving...' : 'Save Profile'}
            </button>
          </div>
        </form>
      </div>
    </ScrollContainer>
  );
};

const ProfileSection: React.FC<{
  title: string;
  items: string[];
  onChange: (newItems: string[]) => void;
}> = ({ title, items, onChange }) => {
  const Icon = sectionIcons[title as keyof typeof sectionIcons];

  const handleInputChange = (index: number, value: string) => {
    const newItems = [...items];
    newItems[index] = value;
    onChange(newItems);
  };

  const handleAddItem = () => {
    onChange([...items, '']);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, idx) => idx !== index);
    onChange(newItems);
  };

  const canAddNewItem =
    items.length === 0 || items[items.length - 1].trim() !== '';

  return (
    <div className="mt-8">
      <div className="mb-2 flex items-center">
        <span className="mr-3 inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
          <Icon className="h-5 w-5" aria-hidden="true" />
        </span>
        <h3 className="text-md font-semibold text-gray-700">{title}</h3>
      </div>
      <div className="mb-4" />
      <ul className="space-y-2">
        {items.map((item, idx) => (
          <li key={idx} className="flex items-center">
            <input
              type="text"
              value={item}
              onChange={(e) => handleInputChange(idx, e.target.value)}
              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder={`Add a ${title.slice(0, -1).toLowerCase()}`}
            />
            <button
              type="button"
              onClick={() => handleRemoveItem(idx)}
              className="ml-2 text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </li>
        ))}
      </ul>
      <button
        type="button"
        onClick={handleAddItem}
        disabled={!canAddNewItem}
        className={`mt-2 ${
          canAddNewItem
            ? 'text-blue-600 hover:text-blue-800'
            : 'cursor-not-allowed text-gray-400'
        }`}
      >
        + Add {title.slice(0, -1)}
      </button>
    </div>
  );
};

const sectionIcons = {
  Regulations: ScaleIcon,
  Services: BriefcaseIcon,
  'Compliance Topics': BookOpenIcon,
  Jurisdictions: GlobeAltIcon,
};

export default CreateProfileView;
