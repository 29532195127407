import { DocumentSummary, NotificationResult } from '@/types/notification';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import { BellIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Badge } from '../Badge';
import ShortDateTime from '../DateTime/ShortDateTime';
import TimeSince from '../DateTime/TimeSince';
import RiskScore from './RiskScore';

const getBadge = (view: 'narrow' | 'wide', riskScore?: number) => {
  if (riskScore) {
    return (
      <div className="flex items-start justify-center">
        <RiskScore score={riskScore} />
      </div>
    );
  }

  return (
    <div
      className={`flex flex-shrink-0 items-start justify-center rounded-full bg-gray-100 text-gray-400 ${
        view === 'narrow' ? 'h-8 w-8' : 'h-10 w-10'
      } `}
    >
      <BellIcon className={`${view === 'narrow' ? 'h-5 w-5' : 'h-6 w-6'} `} />
    </div>
  );
};

function NotificationContent({
  notification,
  setSourceId,
  selectedNotification,
  setSelectedNotification,
  isOpen,
  setIsOpen,
  view,
}: {
  notification: NotificationResult;
  setSourceId: (sourceId: number) => void;
  selectedNotification: NotificationResult | null;
  setSelectedNotification: (notification: NotificationResult | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  view: 'narrow' | 'wide';
}) {
  const isNarrowView = view === 'narrow';

  const Summary = ({
    summary,
    version,
  }: {
    summary: DocumentSummary;
    version: 'short' | 'long';
  }) => {
    const detailedSummaries =
      version === 'long'
        ? summary?.detailedSummaries
        : (summary?.detailedSummaries?.[0]?.bullets?.slice(0, 2) ?? []).map(
            (bullet) => ({
              bullets: [bullet],
            }),
          );

    return (
      <div className="flex w-full flex-col text-sm text-gray-800">
        <div className="w-full text-left">
          <p>{summary?.summary}</p>
        </div>
        {detailedSummaries.map((detailedSummary, index) => (
          <div key={index}>
            <ul className="w-full list-disc pl-4 text-left">
              {detailedSummary.bullets.map((bullet, index) => (
                <li key={index} className="w-full py-2 text-left">
                  <span className="w-full text-left">
                    {bullet.bullet}
                    {bullet.citations.map((citation, index) => (
                      <button
                        key={index}
                        className="inline-flex cursor-pointer items-center pl-1.5 text-left"
                        onClick={() => {
                          setSelectedNotification(notification);
                          setSourceId(Number(citation.sourceId));
                        }}
                      >
                        <LinkIcon className="inline-block h-3 w-3 text-gray-500" />
                      </button>
                    ))}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`relative flex w-full items-center justify-between py-5 ${view === 'narrow' ? 'gap-x-2 px-1' : 'gap-x-6 px-4 sm:px-6'} `}
    >
      <div className={`flex w-full gap-x-4`}>
        {getBadge(view, notification.riskScore)}
        <div className="flex w-full flex-grow flex-col items-start">
          <div className={`flex w-full gap-x-2`}>
            <button
              onClick={() => {
                // if notification is already selected, deselect it
                if (notification === selectedNotification) {
                  setSelectedNotification(null);
                  return;
                }
                setSelectedNotification(notification);
              }}
            >
              <p className="text-left text-sm font-medium leading-6 text-gray-900">
                {notification?.title}
              </p>
            </button>
            {isNarrowView && (
              <div className="flex shrink-0 items-start justify-end">
                <div className="mt-1 text-xs leading-5 text-gray-500">
                  <ShortDateTime dateTime={notification.datePublished} />
                </div>
              </div>
            )}
          </div>
          <div className="mt-1 flex w-full flex-grow text-xs leading-5 text-gray-500">
            <div className="w-full whitespace-pre-wrap pr-20 text-left">
              {notification?.summary && (
                <Summary
                  summary={notification?.summary}
                  version={isOpen ? 'long' : 'short'}
                />
              )}
            </div>
          </div>
          <div className="mt-2 flex w-full justify-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-xs text-gray-500 hover:text-gray-700"
            >
              {isOpen ? (
                <ChevronUpIcon
                  className="h-5 w-5 flex-none"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="h-5 w-5 flex-none"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>

      {!isNarrowView && (
        <>
          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="mt-1 text-xs leading-5 text-gray-500">
                <TimeSince dateTime={notification.datePublished} />
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500">
                <ShortDateTime dateTime={notification.datePublished} />
              </p>
            </div>
          </div>
          <div className="absolute right-0 top-0">
            <Badge variant="outline" className="mr-4 text-gray-700">
              {notification.documentType}
            </Badge>
          </div>
        </>
      )}
    </div>
  );
}

export default function NotificationItem({
  notification,
  selectedNotification,
  setSelectedNotification,
  setSourceId,
  view,
}: {
  notification: NotificationResult;
  selectedNotification: NotificationResult | null;
  setSelectedNotification: (notification: NotificationResult | null) => void;
  setSourceId: (sourceId: number) => void;
  view: 'narrow' | 'wide';
}) {
  const [isOpen, setIsOpen] = useState(
    selectedNotification?.contentId === notification.contentId,
  );

  return (
    <div className="w-full">
      <NotificationContent
        notification={notification}
        setSourceId={setSourceId}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        view={view}
      />
    </div>
  );
}
