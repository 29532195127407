import classNames from 'classnames';
import React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
  className?: string; // Optional className prop
  disabled?: boolean; // Optional disabled prop
  onClick?: () => void; // Optional onClick prop
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  type = 'button',
  className,
  disabled,
  onClick,
}) => {
  const buttonClass = classNames(
    'rounded-full p-2 text-white',
    {
      'bg-blue-600 hover:bg-blue-700': variant === 'primary' && !disabled,
      'bg-gray-500 hover:bg-gray-600': variant === 'secondary' && !disabled,
      'bg-blue-400 cursor-not-allowed': variant === 'primary' && disabled,
      'bg-gray-300 cursor-not-allowed': variant === 'secondary' && disabled,
    },
    className,
  );

  return (
    <button
      type={type}
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
