import {
  getFilterOptions,
  GetFilterOptionsResponse,
} from '@/api/notifications';
import { codeToName } from '@/consts/radarFilters';
import { Filter } from '@/types/filter';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const FILTER_OPTIONS_QUERY_KEY = 'FILTER_OPTIONS';

export function useNotificationFilters({
  profileIds,
}: {
  profileIds: string[];
}) {
  const {
    data: filterOptions,
    error: filterError,
    isFetching: isFetchingFilters,
  } = useQuery<GetFilterOptionsResponse>({
    queryKey: [FILTER_OPTIONS_QUERY_KEY, profileIds],
    queryFn: () => getFilterOptions(profileIds),
    enabled: !!profileIds.length,
  });

  const convertFiltersToSelectFormat = (
    filters: GetFilterOptionsResponse | undefined,
  ): Filter[] => {
    if (!filters) {
      return [
        { id: 'documentType', name: 'Document', options: [] },
        { id: 'jurisdiction', name: 'Jurisdiction', options: [] },
        { id: 'authority', name: 'Authority', options: [] },
        { id: 'topic', name: 'Topic', options: [] },
      ];
    }

    return [
      {
        id: 'documentType',
        name: 'Document',
        options:
          filters.documentTypes
            ?.map((type) => ({
              value: type,
              label: type,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'jurisdiction',
        name: 'Jurisdiction',
        options:
          filters?.countries
            ?.map((country) => ({
              value: country,
              label: codeToName(country) || country,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'authority',
        name: 'Authority',
        options:
          filters.sources
            ?.map((source) => ({
              value: source,
              label: source.replace(/^www\./, ''),
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'topic',
        name: 'Topic',
        options:
          filters.topics
            ?.map((topic) => ({
              value: topic,
              label: topic,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
    ];
  };

  const selectFilterOptions = useMemo(() => {
    return convertFiltersToSelectFormat(filterOptions);
  }, [filterOptions]);

  return {
    selectFilterOptions,
    filterError,
    isFetchingFilters,
  };
}
