import moment from 'moment';

const howManyWeeksAgo = (dateTime: string) => {
  const now = moment();
  const then = moment(dateTime);
  const diff = now.diff(then, 'weeks');
  return diff;
};

const getMoment = (dateTime: string) => {
  return moment(dateTime).calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
  });
};

const formattedDate = (dateTime: string) => {
  const weeks = howManyWeeksAgo(dateTime);
  if (weeks === 1) {
    return `${weeks} wk ago`;
  } else if (weeks > 1) {
    return `${weeks} wks ago`;
  }

  return getMoment(dateTime);
};

export default function TimeSince({ dateTime }: { dateTime: string }) {
  return <span>{formattedDate(dateTime)}</span>;
}
