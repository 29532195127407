import ScrollContainer from '@/components/List/ScrollContainer';
import { RagArticleResult } from '@/types/rag';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { RagSourceSkeleton } from './Skeletons';

interface RagSourcesProps {
  articles: RagArticleResult[];
  onArticleSelect: (article: RagArticleResult) => void;
  isLoading: boolean;
}

const RagSource = ({
  article,
  onClick,
}: {
  article: RagArticleResult;
  onClick: () => void;
}) => (
  <button
    className="flex h-[120px] w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-3 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
    onClick={onClick}
  >
    <div className="flex h-full flex-col justify-between">
      <p className="line-clamp-2 max-w-[95%] text-sm text-gray-700">
        {article.title}
      </p>
      <div className="mt-2 flex flex-col text-sm font-medium leading-6 text-gray-900">
        <p className="max-w-[95%] overflow-hidden truncate text-ellipsis">
          {article.source.slice(0, 30) + '...'}
        </p>
        <p className="mt-1 text-xs text-gray-500">
          {new Date(article.datePublished).toLocaleDateString('en-GB')}
        </p>
      </div>
    </div>
  </button>
);

const ExpandButton = ({
  expanded,
  onClick,
  count,
}: {
  expanded: boolean;
  onClick: () => void;
  count: number;
}) => (
  <div className="flex w-full justify-center">
    <button
      onClick={onClick}
      className="inline-flex items-center rounded-md bg-white px-6 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
    >
      <span>{expanded ? 'Show less' : `Show ${count} more`}</span>
      {expanded ? (
        <ChevronUpIcon className="ml-2 h-5 w-5" aria-hidden="true" />
      ) : (
        <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
      )}
    </button>
  </div>
);

export const RagSources = ({
  articles,
  onArticleSelect,
  isLoading,
}: RagSourcesProps) => {
  const [expanded, setExpanded] = useState(false);

  const rows = expanded ? Math.min(Math.ceil(articles.length / 5), 3.5) : 1;

  useEffect(() => {
    setExpanded(false);
  }, [isLoading]);

  return (
    <div className="relative">
      <ScrollContainer>
        <div
          className={`grid grid-cols-2 gap-y-2 py-2 sm:grid-cols-3 md:grid-cols-4 ${isLoading ? 'gap-x-3' : 'gap-x-1'}`}
          style={{
            transition: 'height 0.3s ease-in-out',
            height: `${rows * 130}px`,
          }}
        >
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <RagSourceSkeleton key={index} />
              ))
            : articles.map((article, index) => (
                <RagSource
                  key={index}
                  onClick={() => onArticleSelect(article)}
                  article={article}
                />
              ))}
        </div>
      </ScrollContainer>
      {articles.length > 5 && (
        <div className="mx-3 mb-1">
          <ExpandButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            count={articles.length - 5}
          />
        </div>
      )}
    </div>
  );
};
