import Accordion from '@/components/Filters/Accordion';
import SearchFilter from '@/components/Filters/SearchFilter'; // Import the SearchFilter component
import { Filter, FilterOption } from '@/types/filter';
import React from 'react';

interface FilterOptionProps {
  section: Filter;
  option: FilterOption;
  onOptionClick?: (option: { section: Filter; option: FilterOption }) => void;
  updateFilters: (checked: boolean) => void;
}

const SelectOption: React.FC<FilterOptionProps> = ({
  section,
  option,
  onOptionClick,
  updateFilters,
}) => (
  <div className="flex items-start">
    <input
      id={`filter-${section.id}-${option.value}`}
      name={`${section.id}[]`}
      value={option.value}
      type="checkbox"
      checked={option.checked}
      className="mt-1 h-4 w-4 flex-shrink-0 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onOptionClick) onOptionClick({ section, option });
        updateFilters(e.target.checked);
      }}
    />
    <label
      htmlFor={`filter-${section.id}-${option.value}`}
      className="ml-3 min-w-0 flex-1 text-sm text-gray-600"
    >
      <span className="inline-block w-full break-all">{option.label}</span>
    </label>
  </div>
);

interface SelectAllOptionProps {
  sectionId: string;
  options: FilterOption[];
  updateAllFilters: (checked: boolean) => void;
}

const SelectAllOption: React.FC<SelectAllOptionProps> = ({
  sectionId,
  options,
  updateAllFilters,
}) => (
  <div className="flex items-center">
    <input
      id={`filter-${sectionId}-all`}
      name={`${sectionId}[]`}
      value="all"
      checked={options.every((o) => o.checked)}
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateAllFilters(e.target.checked)
      }
    />
    <label
      htmlFor={`filter-${sectionId}-all`}
      className="ml-3 text-sm text-gray-600"
    >
      Select All
    </label>
  </div>
);

// Update the main component to use these new typed components
interface SideBarFiltersProps {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  query?: string;
  setQuery?: (query: string) => void;
  header?: JSX.Element;
  onOptionClick?: (option: { section: Filter; option: FilterOption }) => void;
}

const SideBarFilters: React.FC<SideBarFiltersProps> = ({
  header,
  filters,
  setFilters,
  query,
  setQuery,
  onOptionClick,
}) => {
  // Add state for filter searches
  const [filterSearches, setFilterSearches] = React.useState<{
    [key: string]: string;
  }>(Object.fromEntries(filters.map((filter) => [filter.id, ''])));

  // Function to update filter search
  const updateFilterSearch = (filterId: string, search: string) => {
    setFilterSearches((prev) => ({ ...prev, [filterId]: search }));
  };

  // Function to filter options based on search
  const filterOptions = (options: FilterOption[], search: string) => {
    if (!search) return options;
    const lowerSearch = search.toLowerCase();
    return options.filter((option) =>
      option.label.toLowerCase().includes(lowerSearch),
    );
  };

  // Update the FilterOption component
  const updateFilters = (
    sectionId: string,
    optionValue: string,
    checked: boolean,
  ) => {
    setFilters((prevFilters) =>
      prevFilters.map((f) =>
        f.id === sectionId
          ? {
              ...f,
              options: f.options.map((o) =>
                o.value === optionValue ? { ...o, checked: checked } : o,
              ),
            }
          : f,
      ),
    );
  };

  const updateAllFilters = (sectionId: string, checked: boolean) => {
    setFilters((prevFilters) =>
      prevFilters.map((f) =>
        f.id === sectionId
          ? {
              ...f,
              options: f.options.map((o) => ({ ...o, checked: checked })),
            }
          : f,
      ),
    );
  };

  return (
    <div className="bg-white">
      <div>
        <main className="mx-auto px-2 sm:px-4">
          {!!header && (
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
              <div className="flex items-center">{header}</div>
            </div>
          )}
          {setQuery && (
            <div className="space-y-2 pt-4 text-gray-400 hover:text-gray-500">
              <SearchFilter query={query || ''} setQuery={setQuery} />
            </div>
          )}
          <div>
            {/* Filters */}
            <form className="hidden lg:block">
              {filters.map((section) => (
                <Accordion
                  header={section.name}
                  key={section.id}
                  defaultOpen={section.defaultOpen}
                >
                  <div className="space-y-4">
                    {/* Add search bar for each filter */}
                    <SearchFilter
                      query={filterSearches[section.id]}
                      setQuery={(search: string) =>
                        updateFilterSearch(section.id, search)
                      }
                    />
                    {filterOptions(
                      section.options,
                      filterSearches[section.id],
                    ).map((option) => (
                      <SelectOption
                        key={option.value}
                        section={section}
                        option={option}
                        onOptionClick={onOptionClick}
                        updateFilters={(checked: boolean) => {
                          updateFilters(section.id, option.value, checked);
                        }}
                      />
                    ))}
                    {/* last option to slect all that sets all to true on selct and all to false on deselct */}
                    <SelectAllOption
                      sectionId={section.id}
                      options={section.options}
                      updateAllFilters={(checked: boolean) =>
                        updateAllFilters(section.id, checked)
                      }
                    />
                  </div>
                </Accordion>
              ))}
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SideBarFilters;
