import { useRagSummarize } from '@/hooks/useRag';
import { Article, DocumentSummary, Summary } from '@/types/notification';
import { RagArticleResult, RagResult } from '@/types/rag';
import {
  ExclamationTriangleIcon,
  LinkIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {
  DocumentTextIcon,
  MagnifyingGlassIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import { memo, useCallback, useEffect, useState } from 'react';
import ChatFeed from '../Chat/ChatFeed';
import ScrollContainer from '../List/ScrollContainer';
import DocumentAsMarkdown from '../Notification/DocumentAsMarkdown';
import SplitPanel from '../Notification/SplitPanel';
import ValidUrl from '../ValidUrl';
import { RagSources } from './RagSources';
import { ArticleSummary, RagSummary } from './RagSummary';
import { ArticleContentSkeleton, RagSummarySkeleton } from './Skeletons';

const NoSearchResults = memo(() => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <MagnifyingGlassIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">No results found</p>
  </div>
));
NoSearchResults.displayName = 'NoSearchResults';

interface Props {
  results: RagResult;
  selectedResult: RagArticleResult | null;
  setSelectedResult: (result: RagArticleResult | null) => void;
  emptyState: boolean;
  isLoading: boolean;
  summary: DocumentSummary;
  error: Error | null;
  query: string;
}

const SearchResults = ({
  results,
  selectedResult,
  setSelectedResult,
  emptyState,
  isLoading,
  summary,
  error,
  query,
}: Props) => {
  const [sourceId, setSourceId] = useState<number | null>(null);
  const [articleSummaries, setArticleSummaries] = useState<
    Map<RagArticleResult, Summary>
  >(new Map());
  const [loadingArticleSummaries, setLoadingArticleSummaries] = useState<
    Map<RagArticleResult, boolean>
  >(new Map());
  const [articleContents, setArticleContents] = useState<
    Map<string, string[]>
  >(new Map());
  const [loadingArticleContents, setLoadingArticleContents] = useState<
    Map<string, boolean>
  >(new Map());

  const selectedArticle: Article | null = selectedResult
    ? ({
        ...selectedResult,
        chunks: articleContents.get(selectedResult.contentId) || [],
      } as Article)
    : null;

  const { performQuery, getRagContent } = useRagSummarize();

  const getOrLoadArticleSummary = useCallback(
    async (article: RagArticleResult): Promise<Summary | null> => {
      // if present, return the cached summary
      if (articleSummaries.has(article)) {
        return articleSummaries.get(article) || null;
      }

      if (loadingArticleSummaries.has(article)) {
        return null;
      }

      // set the loading state
      setLoadingArticleSummaries((oldSummaries) => {
        oldSummaries.set(article, true);
        return new Map(oldSummaries);
      });

      // otherwise, perform the query and cache the result in client memory
      try {
        const summary = await performQuery(article, results.filters.query);
        if (summary) {
          setArticleSummaries((oldSummaries) => {
            oldSummaries.set(article, summary);
            return new Map(oldSummaries);
          });
        }

        if (summary) {
          return summary as Summary;
        }
        return null;
      } finally {
        // clear the loading state
        setLoadingArticleSummaries((oldSummaries) => {
          oldSummaries.delete(article);
          return new Map(oldSummaries);
        });
      }
    },
    [
      performQuery,
      setLoadingArticleSummaries,
      setArticleSummaries,
      articleSummaries,
      loadingArticleSummaries,
      results.filters.query,
    ],
  );

  const getOrLoadArticleContent = useCallback(
    async (article: RagArticleResult): Promise<string[] | null> => {
      // if present, return the cached summary
      if (articleContents.has(article.contentId)) {
        return articleContents.get(article.contentId) || null;
      }

      if (loadingArticleContents.has(article.contentId)) {
        return null;
      }

      // set the loading state
      setLoadingArticleContents((oldContents) => {
        oldContents.set(article.contentId, true);
        return new Map(oldContents);
      });

      // otherwise, perform the query and cache the result in client memory
      try {
        const content = await getRagContent(article);
        if (content) {
          setArticleContents((oldContents) => {
            oldContents.set(article.contentId, content);
            return new Map(oldContents);
          });
        }

        if (content) {
          return content;
        }
        return null;
      } finally {
        // clear the loading state
        setLoadingArticleContents((oldContents) => {
          oldContents.delete(article.contentId);
          return new Map(oldContents);
        });
      }
    },
    [
      getRagContent,
      setLoadingArticleContents,
      setArticleContents,
      articleContents,
      loadingArticleContents,
    ],
  );

  const setArticleSource = (contentId: string | null, sourceId: number) => {
    setSourceId(sourceId);
    setSelectedResult(
      results.articles.find((article) => article.contentId === contentId) ||
        null,
    );
  };

  useEffect(() => {
    if (selectedResult) {
      getOrLoadArticleContent(selectedResult);
    }
  }, [selectedResult]);

  if (emptyState) {
    return <NoSearchResults />;
  }

  if (selectedArticle) {
    return (
      <div className="mx-auto h-full max-w-[70%] pt-8">
        <SplitPanel
          leftContent={
            <div className="flex h-full flex-col overflow-hidden">
              <ChatFeed
                header={
                  <>
                    <RagSummary
                      summary={summary}
                      onSelectSource={setArticleSource}
                    />
                    {results.articles.map((article, index) => (
                      <ArticleSummary
                        key={index}
                        article={article}
                        getSummary={getOrLoadArticleSummary}
                        isLoading={loadingArticleSummaries.has(article)}
                        version={article === selectedResult ? 'long' : 'short'}
                        onSelectSource={setArticleSource}
                      />
                    ))}
                  </>
                }
                article={selectedArticle}
              />
            </div>
          }
          rightContent={
            <div className="flex h-full flex-col border-l border-gray-200">
              <div className="flex items-start justify-between px-4 py-2 shadow-sm">
                <ValidUrl url={selectedArticle?.url}>
                  <div className="flex items-start text-gray-800 hover:text-blue-500 hover:underline">
                    <div className="text-sm font-medium">
                      {selectedArticle?.title ||
                        (articleContents.get(selectedArticle.contentId) &&
                          articleContents
                            .get(selectedArticle.contentId)![0]
                            .substring(0, 50)) ||
                        ''}
                    </div>
                    <LinkIcon
                      className="ml-1.5 mt-0.5 h-4 w-4 cursor-pointer text-gray-500 hover:text-blue-500"
                      aria-hidden="true"
                    />
                  </div>
                </ValidUrl>
                <div className="flex justify-end px-2">
                  <button
                    onClick={() => {
                      setSelectedResult(null);
                      setSourceId(null);
                    }}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="flex-1 overflow-auto">
                <ScrollContainer>
                  <div className="overflow-y-auto">
                    {articleContents.get(selectedArticle.contentId) &&
                    articleContents.get(selectedArticle.contentId)!.length >
                      0 ? (
                      <DocumentAsMarkdown
                        doc={selectedArticle}
                        highlightIndex={sourceId}
                      />
                    ) : loadingArticleContents.has(
                        selectedArticle.contentId,
                      ) ? (
                      <ArticleContentSkeleton />
                    ) : (
                      <div className="flex-col p-8 text-center text-sm text-gray-800">
                        <div className="">
                          The document is not available for preview
                        </div>
                        <div>
                          Please view the original document{' '}
                          <ValidUrl url={selectedArticle.url}>
                            <p className="text-blue-500 hover:underline">
                              here
                            </p>
                          </ValidUrl>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollContainer>
              </div>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="mx-auto h-full w-full max-w-3xl px-4">
        {error ? (
          <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
            <ExclamationTriangleIcon className="mb-4 h-12 w-12 text-gray-400" />
            <p className="text-md mt-2">
              {
                "We're sorry, but we're having trouble fetching search results."
              }
            </p>
          </div>
        ) : (
          <div className="h-full pt-8">
            <div className="">
              <p className="mt-2 line-clamp-2 text-gray-700">{query}</p>
              <div className="my-4 border-t border-gray-200" />
              <h2 className="flex items-center gap-2 text-lg font-medium text-gray-900">
                <Square3Stack3DIcon className="h-5 w-5 text-gray-600" />
                Sources
              </h2>
              <RagSources
                articles={results.articles}
                onArticleSelect={setSelectedResult}
                isLoading={isLoading}
              />
            </div>
            {isLoading || !summary.summary ? (
              <RagSummarySkeleton />
            ) : (
              <div className="h-[75%] flex-1 pt-2">
                <h2 className="mb-3 flex items-center gap-2 text-lg font-medium text-gray-900">
                  <DocumentTextIcon className="h-5 w-5 text-gray-600" />
                  Answer
                </h2>
                <RagSummary
                  summary={summary}
                  onSelectSource={setArticleSource}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
