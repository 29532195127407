import ProfileList from '@/components/Profiles/ProfileList';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { useProfilesContext } from '@/providers/profilesProvider';

const ProfilePage = () => {
  const { profiles } = useProfilesContext();

  return (
    <NavLayout>
      <div className="flex h-full w-full">
        <ProfileList profiles={profiles || []} />
      </div>
    </NavLayout>
  );
};

export default ProfilePage;
