import mockResponse from '@/__mocks__/mockRagResponse.json';
import { Jurisdiction } from '@/types';
import { Summary } from '@/types/notification';
import { RagArticleResult, RagResult } from '@/types/rag';
import api, { deepCamelCaseTransform } from './index';
import streamApi from './streamApi';

export interface QueryParams {
  query: string;
  limit: number;
  offset: number;
  abortSignal?: AbortSignal | undefined;
  filters?: {
    source?: string[];
    documentType?: string[];
    dateAfter?: Date | null;
    dateBefore?: Date | null;
    company?: string[];
    topic?: string[];
    regulation?: string[];
    regulator?: string[];
    jurisdiction?: Jurisdiction[];
  };
}

export interface SummarizeParams {
  query: string;
  content_id: string;
  chunk_ids: string[];
}

export interface SummarizeStreamParams {
  summaries: Summary[];
}

export interface GetRagFilterOptionsResponse {
  documentTypes: string[];
  sources: string[];
  countries: string[];
  states: string[];
  regions: string[];
  topics: string[];
}

const BASE_URL = '/query';

export const summarizeRag = async (
  params: SummarizeParams,
): Promise<Summary> => {
  try {
    const response = await api.post<Summary>(BASE_URL + '/summarize', params);
    return response.data;
  } catch (error) {
    console.error('Error summarizing:', error);
    throw error;
  }
};

async function* streamAsyncIterator(stream: ReadableStream) {
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  try {
    let jsonBuilder = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      if (value) {
        const decodedValue = decoder.decode(value);
        jsonBuilder += decodedValue;

        if (
          jsonBuilder.includes('$$JSON$$') &&
          jsonBuilder.includes('$$END$$')
        ) {
          const jsonParts = jsonBuilder.split('$$JSON$$');
          for (let i = 1; i < jsonParts.length; i++) {
            const endIndex = jsonParts[i].indexOf('$$END$$');
            if (endIndex !== -1) {
              const jsonString = jsonParts[i].substring(0, endIndex);
              try {
                const jval = JSON.parse(jsonString);
                const camelJval = deepCamelCaseTransform(jval);
                yield camelJval;
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
              jsonBuilder = jsonParts[i].substring(endIndex + 7); // Remove processed part
            }
          }
        } else if (!jsonBuilder.includes('$$JSON$$')) {
          yield decodedValue;
          jsonBuilder = '';
        }
      }
    }
  } finally {
    reader.releaseLock();
  }
}

export const queryRagStream = async (
  params: QueryParams,
  signal: AbortSignal | undefined,
): Promise<AsyncGenerator<string, void, unknown>> => {
  try {
    const response = await streamApi.post(BASE_URL, params, { signal });

    if (!response.body) {
      throw new Error('No response body');
    }

    return streamAsyncIterator(response.body);
  } catch (error) {
    console.error('Error summarizing stream:', error);
    throw error;
  }
};

export const queryRag = async (params: QueryParams): Promise<RagResult> => {
  if (process.env.NODE_ENV === 'development') {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const mock = mockResponse as unknown as RagResult;
    mock.articles.forEach((article: RagArticleResult) => {
      article.datePublished = new Date(article.datePublished);
    });
    // make more copies of the articles
    mock.articles = [
      ...Array(10).fill(mock.articles[0]),
      ...Array(10).fill(mock.articles[1]),
    ];
    return mock;
  }
  try {
    const response = await api.post<RagResult>(BASE_URL, params, {
      signal: params.abortSignal,
    });
    response.data.articles.forEach((article: RagArticleResult) => {
      article.datePublished = new Date(article.datePublished);
    });
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.name === 'CanceledError') {
      console.log('Search request was aborted');
    } else {
      console.error('Error searching articles:', error);
    }
    throw error;
  }
};

export const getRagFilterOptions =
  async (): Promise<GetRagFilterOptionsResponse> => {
    const response =
      await api.get<GetRagFilterOptionsResponse>('/query/filters');
    return response.data;
  };

export const getRagContent = async (
  article: RagArticleResult,
): Promise<string[]> => {
  const response = await api.get<string[]>(
    `/query/content/${article.contentId}`,
  );
  return response.data;
};
