import { navigationMap } from '@/consts/navigation';
import { useProfiles } from '@/hooks/useProfiles';
import { Profile } from '@/types/profiles';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUserContext } from './userProvider';

const PROFILES_KEY = 'PROFILES';

type ProfilesContextType = {
  profiles: Profile[] | undefined;
  setProfiles: (profiles: Profile[]) => void;
  refetchProfiles: () => Promise<void>;
};

const ProfilesContext = createContext<ProfilesContextType | undefined>(
  undefined,
);

export const ProfilesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [profiles, setProfilesState] = useState<Profile[] | undefined>(() => {
    const storedProfiles = localStorage.getItem(PROFILES_KEY);
    return storedProfiles ? JSON.parse(storedProfiles) : undefined;
  });
  const { user } = useUserContext();
  const { profiles: fetchedProfiles, refetch } = useProfiles(user?.userId);

  useEffect(() => {
    if (fetchedProfiles !== undefined) {
      if (
        fetchedProfiles.length === 0 &&
        window.location.pathname !== navigationMap.profile.href
      ) {
        // window.location.href = navigationMap.profile.href;
      } else {
        setProfilesState(fetchedProfiles);
        localStorage.setItem(PROFILES_KEY, JSON.stringify(fetchedProfiles));
      }
    }
  }, [fetchedProfiles]);

  const setProfiles = useCallback(
    (newProfiles: Profile[]) => {
      setProfilesState(newProfiles);
      localStorage.setItem(PROFILES_KEY, JSON.stringify(newProfiles));
    },
    [setProfilesState],
  );

  const refetchProfiles = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const value = {
    profiles,
    setProfiles,
    refetchProfiles,
  };

  return (
    <ProfilesContext.Provider value={value}>
      {children}
    </ProfilesContext.Provider>
  );
};

export const useProfilesContext = () => {
  const context = useContext(ProfilesContext);
  if (context === undefined) {
    throw new Error(
      'useProfilesContext must be used within a ProfilesProvider',
    );
  }
  return context;
};

export default ProfilesProvider;
