import { getRagFilterOptions, GetRagFilterOptionsResponse } from '@/api/rag';
import { codeToName } from '@/consts/radarFilters';
import { Filter } from '@/types/filter';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

const RAG_FILTER_OPTIONS_QUERY_KEY = 'RAG_FILTER_OPTIONS';
const ONE_HOUR_IN_MS = 1000 * 60 * 60;

interface RagFiltersContextType {
  selectFilterOptions: Filter[];
  filterError: Error | null;
  isFetchingFilters: boolean;
  refetchFilters: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<GetRagFilterOptionsResponse, Error>>;
}

const RagFiltersContext = createContext<RagFiltersContextType>({
  selectFilterOptions: [],
  filterError: null,
  isFetchingFilters: true,
  refetchFilters: async (
    options?: RefetchOptions,
  ): Promise<QueryObserverResult<GetRagFilterOptionsResponse, Error>> => {
    throw new Error('RagFiltersContext not initialized');
  },
});

export function RagFiltersProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    data: filterOptions,
    error: filterError,
    isFetching: isFetchingFilters,
    refetch: refetchFilters,
  } = useQuery<GetRagFilterOptionsResponse, Error>({
    queryKey: [RAG_FILTER_OPTIONS_QUERY_KEY],
    queryFn: () => getRagFilterOptions(),
    staleTime: ONE_HOUR_IN_MS,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const selectFilterOptions = convertFiltersToSelectFormat(filterOptions);

  const value: RagFiltersContextType = {
    selectFilterOptions,
    filterError: filterError,
    isFetchingFilters,
    refetchFilters,
  };

  return (
    <RagFiltersContext.Provider value={value}>
      {children}
    </RagFiltersContext.Provider>
  );
}

// Move the conversion function here since it's used by both the provider and hook
function convertFiltersToSelectFormat(
  filters: GetRagFilterOptionsResponse | undefined,
): Filter[] {
  if (!filters) {
    return [
      { id: 'documentType', name: 'Document', options: [] },
      { id: 'jurisdiction', name: 'Jurisdiction', options: [] },
      { id: 'authority', name: 'Authority', options: [] },
      { id: 'topic', name: 'Topic', options: [] },
    ];
  }

  return [
    {
      id: 'documentType',
      name: 'Document',
      options:
        filters.documentTypes
          ?.map((type) => ({
            value: type,
            label: type,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
    {
      id: 'jurisdiction',
      name: 'Jurisdiction',
      options:
        filters.countries
          ?.map((country) => ({
            value: country,
            label: codeToName(country) || country,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
    {
      id: 'authority',
      name: 'Authority',
      options:
        filters.sources
          ?.map((source) => ({
            value: source,
            label: source.replace(/^www\./, ''),
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
    {
      id: 'topic',
      name: 'Topic',
      options:
        filters.topics
          ?.map((topic) => ({
            value: topic,
            label: topic,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
  ];
}

// Update the hook to use the context
export function useRagFilters() {
  return useContext(RagFiltersContext);
}
